// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-optional-offer-nt-index-js": () => import("./../../../src/pages/lp/optional-offer-nt/index.js" /* webpackChunkName: "component---src-pages-lp-optional-offer-nt-index-js" */),
  "component---src-pages-lp-prd-best-index-js": () => import("./../../../src/pages/lp/prd-best/index.js" /* webpackChunkName: "component---src-pages-lp-prd-best-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-terms-of-service-index-js": () => import("./../../../src/pages/terms-of-service/index.js" /* webpackChunkName: "component---src-pages-terms-of-service-index-js" */),
  "component---src-pages-tewst-js": () => import("./../../../src/pages/tewst.js" /* webpackChunkName: "component---src-pages-tewst-js" */),
  "component---src-pages-ty-amo-thank-you-index-js": () => import("./../../../src/pages/ty/amo-thank-you/index.js" /* webpackChunkName: "component---src-pages-ty-amo-thank-you-index-js" */),
  "component---src-pages-ty-thank-you-index-js": () => import("./../../../src/pages/ty/thank-you/index.js" /* webpackChunkName: "component---src-pages-ty-thank-you-index-js" */),
  "component---src-pages-uninstall-index-js": () => import("./../../../src/pages/uninstall/index.js" /* webpackChunkName: "component---src-pages-uninstall-index-js" */),
  "component---src-pages-unsubscribe-index-js": () => import("./../../../src/pages/unsubscribe/index.js" /* webpackChunkName: "component---src-pages-unsubscribe-index-js" */)
}

